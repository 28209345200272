<template>
  <div class="flexbox vertical">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>{{ $t('中国仓库收货地址') }}</span>
      <span></span>
    </header>
    <main class="flex-fill page-body">
      <div class="address round">
        <div class="theme-color indent">
          {{ $t('EnterPackage.kqs54j') }}： {{ stock_name }}
        </div>
        <div class="indent">
          <div>
            <span class="inf">
              {{ $t('EnterPackage.5jllo7') }} {{ stock_rec_name }}
            </span>
            <van-button
              class="mini-button"
              size="mini"
              id="copy"
              :data-clipboard-text="stock_rec_name"
              @click="copy"
              type="info"
              >{{ $t('复制') }}</van-button
            >
          </div>
          <div>
            {{ $t('EnterPackage.bzs54i') }} {{ stock_rec_tel }}
            <van-button
              class="mini-button"
              size="mini"
              id="copy"
              :data-clipboard-text="stock_rec_tel"
              @click="copy"
              type="info"
              >{{ $t('复制') }}</van-button
            >
          </div>
          <div>
            {{ $t('EnterPackage.f4o25k') }} {{ stock_rec_zipcode }}
            <van-button
              class="mini-button"
              size="mini"
              id="copy"
              :data-clipboard-text="stock_rec_zipcode"
              @click="copy"
              type="info"
              >{{ $t('复制') }}</van-button
            >
          </div>
          <div>
            {{ $t('EnterPackage.uc0c1f') }} {{ stock_rec_addr }}
            <van-button
              class="mini-button"
              size="mini"
              id="copy"
              :data-clipboard-text="stock_rec_addr"
              @click="copy"
              type="info"
              >{{ $t('复制') }}</van-button
            >
          </div>
          <div>
            {{ $t('EnterPackage.8qeqyj') }} {{ stock_rec_remark }}
            <van-button
              class="mini-button"
              size="mini"
              id="copy"
              :data-clipboard-text="stock_rec_remark"
              @click="copy"
              type="info"
              >{{ $t('复制') }}</van-button
            >
          </div>
          <div>{{ $t('EnterPackage.sb9f6s') }} {{ stock_rec_worktime }}</div>
          <div>{{ $t('EnterPackage.o6hjut') }}</div>
        </div>
        <div class="tip">
          {{ $t('EnterPackage.fljdjs') }}
          <div class="danger">
            {{ $t('请务必备注您的会员号，否则仓库入库无法确认所属客户') }}
          </div>
        </div>
        <div class="express-tns">
          <van-button
            type="info"
            @click="$router.push({ name: 'EnterPackage' })"
            >{{ $t('包裹预报') }}</van-button
          >
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { $t } from '@/plugins/i18n';
import { getStockInfo } from '@/api/deliver.js';
import Clipboard from 'clipboard';
export default {
  data() {
    return {
      orderNo: '',
      name: '',
      note: '',
      declarePrice: 0,
      stock_name: '',
      stock_rec_name: '',
      stock_rec_tel: '',
      stock_rec_zipcode: '',
      stock_rec_addr: '',
      stock_rec_remark: '',
      stock_rec_worktime: '',
      mes: '地址',
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getStockInfo().then(res => {
        if (res.Result == true) {
          var stockInfo = res.DataDict['data'];
          this.stock_name = stockInfo.name;
          this.stock_rec_name = stockInfo.recv;
          this.stock_rec_tel = stockInfo.tel;
          this.stock_rec_zipcode = stockInfo.code;
          this.stock_rec_addr = stockInfo.addr;
          this.stock_rec_remark = stockInfo.note;
          this.stock_rec_worktime = stockInfo.worktime;
          if (res.DataDict['custNo'] != null) {
            this.stock_rec_remark += '(' + res.DataDict['custNo'] + ')';
          }
        }
      });
    },
    copy() {
      let _this = this;
      let clipborad = new Clipboard('#copy');
      //复制成功
      clipborad.on('success', function() {
        _this.$toast.success($t('复制成功！'));
        clipborad.destory();
      });
      //复制失败
      clipborad.on('error', function() {
        _this.$toast.fail($t('复制失败！'));
        clipborad.destory();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/_var';
.page-body {
  padding: rem(20px);
}
.round {
  border-radius: rem(10px);
}
.note {
  color: #b8b8b8;
  font-size: 12px;
  line-height: 1.5;
  margin: rem(20px) 0;
}
.express-tns {
  margin: 10px;
  text-align: center;
}
.van-button {
  height: rem(80px);
  width: rem(500px);
  background-color: $theme-color;
  border: none;
  border-radius: rem(25px);
}
.x-center {
  margin: rem(40px) 0;
}
.address {
  background: rgba(#9c9bb4, 0.3);
  padding: rem(20px);
  padding-bottom: rem(40px);
}

.indent {
  margin-left: rem(40px);
  line-height: 2;
  .inf {
    line-height: 0.5rem;
  }
}
.tip {
  padding: rem(32px);
  background: #f6f6f6;
  border-radius: rem(10px);
  .danger {
    color: rgb(235, 8, 8);
  }
}
.text-white {
  color: #fff;
}
.mini-button {
  width: 0.8rem;
  height: 0.4rem;
}
</style>
